import * as Sentry from "@sentry/vue";
import JSZip from "jszip"; 

export async function downloadAsZip(artifactUrls, folderName, replaceFileName = false) {
	const zip = new JSZip();
	const folder = zip.folder(folderName);

	const downloadFile = async (url) => {
		const separator = url.includes("?") ? "&" : "?";
		const fileUrl = url + separator + "timestamp=" + new Date().getTime();
		let fileName = fileUrl.split("?")[0].substring(fileUrl.lastIndexOf("/") + 1);

		if (replaceFileName) {
			fileName = fileName.replace("snapshot", "file");
		}

		try {
			const response = await fetch(fileUrl);
			if (!response.ok) {
				throw new Error(`Failed to fetch ${fileUrl}: ${response.statusText}`);
			}
			const blob = await response.blob();
			folder.file(fileName, blob);
		} catch (error) {
			console.error(`Failed to download file from ${fileUrl}:`, error);
			Sentry.captureException(error, {
				tags: {
					method: "downloadAsZip",
					file: "download.js",
				},
				extra: {
					fileUrl: fileUrl,
				},
			});
		}
	};

	const downloadPromises = artifactUrls.map((url) => downloadFile(url));

	await Promise.all(downloadPromises);

	zip.generateAsync({ type: "blob" }).then((content) => {
		const link = document.createElement("a");
		link.href = URL.createObjectURL(content);
		link.download = `${folderName}.zip`;
		link.click();
	});
}
