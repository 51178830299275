<template>
	<v-card :class="cardClass()" class="pa-0">
		<v-card-text>
			<div class="header">
				<div>
					<h2>Add <span class="action-header-upload-photo">Photos or Documents</span>.</h2>
				</div>
			</div>
			<v-form v-model="form" :autocomplete="autocomplete">
				<!--Enter a phone number-->

				<v-text-field
					:autocomplete="autocomplete"
					v-model="title"
					ref="title"
					:placeholder="defaultTitle"
					hide-details
					label="Session Name"
					outlined
					dense
					clearable
					:maxLength="56"
					@change="titleChanged"
					class="field-border-radius" />

				<v-autocomplete
					:autocomplete="autocomplete"
					ref="customers"
					dense
					class="autocomplete"
					hide-details
					outlined
					label="Contact"
					no-data-text="No contacts found"
					placeholder="Contact is optional"
					counter="60"
					v-model="selectedCustomer"
					item-text="searchLabel"
					item-value="id"
					return-object
					cache-items
					@input="customerSelected"
					@click:clear="clearCustomer"
					:items="customerItems"
					:maxLength="60"
					:search-input.sync="searchCustomers">
					<template v-slot:selection="{ attr, on, item, selected }">
						<span v-if="item.name">{{ item.name }}</span>
						<span v-else-if="item.phone">{{ item.phone }}</span>
						<span v-else-if="item.email">{{ item.email }}</span>
					</template>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
							<v-list-item-subtitle v-if="item.phone"
								><span>{{ item.phone }}</span></v-list-item-subtitle
							>
							<v-list-item-subtitle v-if="item.email"
								><span>{{ item.email }}</span></v-list-item-subtitle
							>
						</v-list-item-content>
					</template>
				</v-autocomplete>

				<ProjectAutoComplete
					:selected-project="newSelectedProject"
					:placeholder="'Project is optional'"
					@change="updateProject" />
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-spacer />

			<!--Close-->
			<v-btn text elevation="0" @click="close()"> Back </v-btn>

			<v-file-input
				ref="filePicker"
				rounded
				multiple
				:accept="accept"
				prepend-icon="icon-camera-stroke"
				class="file-picker d-none"
				@change="filesChanged">
			</v-file-input>

			<!--Start-->
			<v-btn color="primary" elevation="0" :disabled="startButtonDisabled()" :loading="starting" @click="start()">
				{{ dynamicActioningText }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import api from "../api";
	import "../helpers/emoji";
	import Projects from "../helpers/projects";
	import tokenHandler from "../helpers/tokenHandler";
	import constants from "../api/constants";
	import Dialogs from "../helpers/dialogs";
	import ProjectAutoComplete from "./ProjectAutoComplete.vue";
	import timezones from "@/helpers/timezones";
	import DeviceUtility from "@/helpers/device";
	import EventBus from "@/helpers/eventBus";

	export default {
		components: {
			ProjectAutoComplete,
		},
		props: {
			customerId: String,
			actionText: String,
			actioningText: String,
			buttonIcon: String,
			showHeader: Boolean,
			projectId: String,
		},
		data() {
			return {
				showPhoneFieldErrorState: false,
				autocomplete: Math.random(),
				form: false,
				currentTab: null,
				vueTelProps: window.VueTelProps,
				starting: false,
				validPhone: false,
				autoSetName: false,
				autoSetNameValue: "",
				emailDisabled: true,
				email: "",
				phone: "",
				phoneSearch: "",
				title: "",
				name: "",
				contactName: "",
				includeEmailInvitation: false,
				meetingUrl: "",
				actioningTextOverride: "",
				organization: {},
				autoStartRecordings: true,
				notificationTemplate: "",
				defaultNotificationTemplate: "",
				shareUser: null,
				me: null,
				customers: [],
				selectedCustomer: {},
				validCustomer: true,
				searchCustomers: null,
				phoneSearchResultsOpen: false,
				customerJustSelected: false,
				newSelectedProject: null,
				projectSelectItems: [],
				isCameraOpen: false,
				capturedImage: null,
				cameraWindow: null,
				stream: null,
				filesToUpload: [],
			};
		},
		computed: {
			accept() {
				let accept = "image/jpg, image/jpeg, image/png";

				if (!DeviceUtility.isAndroid) {
					accept = `${accept}, application/pdf`;
				}

				return accept;
			},
			dynamicActioningText() {
				if (this.actioningTextOverride) {
					return this.actioningTextOverride;
				}
				return "Select Files";
			},
			defaultTitle() {
				if (!this.me) {
					return "Files on " + timezones.formatDate(new Date().toISOString(), "M/D/YYYY");
				}
				return `Files from ${
					this.me.user.firstname
						? this.me.user.firstname + (this.me.user.lastname ? " " + this.me.user.lastname : "")
						: this.me.user.email
				} on ${timezones.formatDate(new Date().toISOString(), "M/D/YYYY")}`;
			},
			customerItems() {
				// TODO: Create a helper function out of this to apply to all places where a combobox/autocomplete uses it
				return this.customers
					.map((customer) => {
						let name = `${customer.firstname} ${customer.lastname}`.trim();
						let phone = null;
						let email = null;
						let searchLabel = name;

						if (name === "") {
							name = null;
						}

						if (customer.PhoneNumbers?.length > 0) {
							phone = customer.PhoneNumbers[0].phoneNumber;
							searchLabel = `${searchLabel} ${phone.replaceAll(" ", "")}`;
						}

						if (customer.EmailAddresses?.length > 0) {
							email = customer.EmailAddresses[0].emailAddress;
							searchLabel = `${searchLabel} ${email}`;
						}

						return Object.assign({}, customer, { name, phone, email, searchLabel });
					})
					.filter((x) => x.searchLabel.trim() !== "");
			},
		},
		async mounted() {
			this.me = await api.getMe(true, true, true);
			if (this.projectId) {
				const result = await api.getProject(this.projectId);
				this.newSelectedProject = result;
			}
			this.title = this.defaultTitle;
			this.reset();
			this.organization = this.$root.$organization; //JSON.parse(localStorage.getItem("org"));
			this.customers = await Dialogs.getCustomers();
			if (this.customerId) {
				const result = await api.getCustomers({
					where: {
						id: this.customerId,
					},
				});

				if (result.count > 0) {
					this.customers = result.rows;
					let customer = result.rows[0];
					const name = customer.firstname
						? `${customer.firstname} ${customer.lastname}`.trim()
						: customer.PhoneNumbers && customer.PhoneNumbers.length > 0
						? customer.PhoneNumbers[0].phoneNumber
						: "";
					customer = Object.assign({}, customer, { name });
					this.setCustomer(customer);
				}
			}
		},
		methods: {
			async updateProject(project) {
				this.newSelectedProject = project;
			},
			cardClass() {
				return `conversation-card ${DeviceUtility.isMobile ? "conversation-bottom" : ""} conversation-bottom ${
					this.conversationType
				}`;
			},
			startButtonDisabled() {
				return (
					!this.form ||
					this.starting ||
					!!this.actioningTextOverride ||
					!this.title ||
					this.title.trim() == ""
				);
			},
			close() {
				this.reset();
				if (DeviceUtility.isMobile) {
					if (!this.$route.query.from) {
						this.$router.push("/");
					} else {
						this.$router.go(-1);
					}
				}
				this.$emit("close-menu");
			},
			reset() {
				this.phone = "";
				this.email = "";
				this.includeEmailInvitation = false;
				this.name = "";
				this.autoSetName = "";
				this.autoSetNameValue = "";
				this.shareUser = null;
			},
			isAdmin() {
				if (this.me?.account.roles[0].name.toLowerCase() === "user") {
					return false;
				}
				return true;
			},
			filesChanged(files) {
				this.starting = true;
				for (const file of files) {
					if (file) {
						if (
							!/image\/png|jpg|jpeg/i.test(file.type) &&
							!/video\/mp4|hevc|quicktime|mov/i.test(file.type) &&
							!/application\/pdf/i.test(file.type)
						) {
							this.$root.$dangerzone.open(
								"Invalid File Type",
								`Please select an image, video, or pdf file to upload.`,
								"OK"
							);
							return;
						}

						this.filesToUpload.push(file);
					}
				}
				this.uploadFiles().then(() => {
					this.starting = false;
				});
			},
			titleChanged() {
				if (!this.title || this.title == "") {
					this.title = this.defaultTitle;
				}
			},
			customerSelected(customer) {
				if (customer) {
					this.customerJustSelected = true;
				}

				this.setCustomer(customer);
			},
			async clearCustomer() {
				this.setCustomer(null);
				this.phone = "";
				this.customers = await Dialogs.getCustomers();
			},
			setCustomer(customer) {
				this.selectedCustomer = customer;
				if (customer) {
					this.validCustomer = true;
					this.name = customer.firstname + " " + customer.lastname;
					this.autoSetName = true;
					this.autoSetNameValue = this.name;

					if (customer.EmailAddresses?.length > 0) {
						this.email = customer.EmailAddresses[0].emailAddress;
						this.emailDisabled = true;
					} else {
						this.emailDisabled = false;
					}

					if (!this.phone && customer.PhoneNumbers?.length > 0) {
						this.phone = customer.PhoneNumbers[0].phoneNumber;
					}
				} else {
					this.validCustomer = false;
					this.emailDisabled = false;
					this.name = "";
					this.email = "";
				}
			},
			async start() {
				// open camera
				var picker = this.$refs.filePicker.$refs.input;
				picker.click();
			},
			async uploadFiles() {
				const me = await api.getMe();
				if (this.newSelectedProject && this.selectedCustomer?.id) {
					const selectedContact = this.selectedCustomer;
					let contactExistance = false;
					if (!this.newSelectedProject.Customers) {
						this.newSelectedProject.Customers = [];
					}
					for (const contact of this.newSelectedProject.Customers) {
						if (contact.id === selectedContact.id) {
							contactExistance = true;
							break;
						}
					}
					if (!contactExistance) {
						await api.updateProject(this.newSelectedProject.id, {
							Customers: [...this.newSelectedProject.Customers, selectedContact],
						});
					}
				}

				const request = {
					forcedUserId: me.user.id,
					name: this.title ?? "Files Upload",
					type: constants.CONVERSATION_TYPE.UPLOADS,
					initiationType: constants.INITIATION_TYPE.ADMIN,
					projectId: this.newSelectedProject?.id,
				};

				if (this.selectedCustomer.id) {
					request.visitors = [
						{
							customerId: this.selectedCustomer.id,
							phone: this.phone.trim(),
							email: this.email.trim(),
							firstname: this.selectedCustomer.firstname,
							lastname: this.selectedCustomer.lastname,
							notificationSMS: false,
							notificationEmail: false,
						},
					];
				}

				const resp = await api.createConversation(request);
				const conversation = resp.conversation;
				const promises = [];
				for (const file of this.filesToUpload) {
					promises.push(
						api.uploadFiles(conversation.id, file, null, {
							conversationId: `${conversation.id}`,
							description: `${conversation.name}`,
						})
					);
				}
				await Promise.all(promises);
				this.close();
				this.$root.$toast.open(null, `Files were uploaded successfully.`, 2000);
				this.starting = false;
				// reroute to conversation page
				if (DeviceUtility.isMobile) {
					if (this.$route.query.from !== "homepage") {
						this.$router.go(-1);
					} else {
						this.$router.push({
							name: "conversation",
							params: {
								id: conversation.id,
							},
							query: {
								from: "homepage",
							},
						});
					}
				} else {
					const currentUrl = window.location.href;
					this.$emit("close-menu");
					if (currentUrl.includes("/projects")) {
						EventBus.$emit("ReloadPhotos", this.projectId);
					} else {
						this.$router.push({
							name: "conversation",
							params: {
								id: conversation.id,
							},
						});
					}
				}
			},
		},
		watch: {
			searchCustomers(searchTerm) {
				clearTimeout(this._searchTimerId);
				this._searchTimerId = setTimeout(async () => {
					const customers = await Dialogs.getCustomers(searchTerm);
					this.customers = customers;
				}, 500);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.start-conversation-button {
		font-family: "Inter Tight", sans-serif;
		font-size: 20px;
		color: white;
		margin-left: 10px;
	}
	.reset-span {
		position: relative;
		left: calc(100% - 50px);
		display: inline-block;
		top: -30px;
		height: 0;
	}

	.conversation-card {
		border-radius: 12px;
		min-height: 400px;
		display: flex;
		flex-direction: column;
		justify-content: start;
	}

	.conversation-card .header {
		display: flex;
		margin-bottom: 28px;
		margin-top: 12px;
	}

	.conversation-card textarea {
		color: #212121;
		font-size: 16px;
	}

	.conversation-card h2,
	.conversation-card i {
		font-size: 24px;
		margin-right: 5px;
	}

	.conversation-card .v-card__actions {
		padding: 16px;
		display: flex;
		justify-content: space-evenly;
	}

	.conversation-card .v-card__actions > button {
		width: 50%;
		height: 50px;
		border-radius: 25px;
		line-height: 20px;
		font-size: 1em;
		font-weight: 600;
	}

	.conversation-card .header {
		color: #000;
	}

	.conversation-card .auto-message {
		color: #b3b3b3;
		font-size: 16px;
	}

	.conversation-card .record-switch {
		margin-left: 5px;
		margin-top: 0px;
	}

	.conversation-card .v-card__actions > button:nth-child(2) {
		display: block;
		border: 1px solid #e0e0e0 !important;
	}

	.conversation-card .contact-name.reset {
		padding-top: 0px;
	}

	.field-border-radius {
		border-radius: 6px;
	}

	.v-slide-group__next,
	.v-slide-group__prev {
		display: none;
	}

	/* Email Invite */

	.team-member-share {
		position: absolute;
		padding-top: 8px;
		margin-left: 2px;
		color: #0070ff;
	}

	.customer-phone-search {
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
	}
</style>
