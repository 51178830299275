<template>
	<div>
		<v-autocomplete
			v-if="density"
			:autocomplete="autocomplete"
			density="compact"
			class="autocomplete"
			hide-details
			outlined
			ref="projectSearch"
			label="Project"
			auto-select-first
			no-data-text="No projects found."
			:placeholder="placeholder"
			counter="60"
			:maxLength="60"
			:disabled="hasFreeTrialEnded"
			v-model="localSelectedProject"
			item-text="name"
			item-value="id"
			return-object
			:items="filteredProjectItems"
			@update:search-input="filterAutoCompleteResultsForProject">
			<template v-slot:selection="{ item }">
				<!-- item name with break word on overflow-->
				<div class="break-word">{{ item.name }}</div>
			</template>
			<template v-slot:item="{ item }">
				<v-list-item-content v-if="!item.edit">
					<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
				</v-list-item-content>
			</template>
			<template v-if="projectsLoaded && filteredProjectItems.length == 0" v-slot:append>
				<v-slide-x-reverse-transition mode="out-in">
					<v-tooltip :open-on-hover="!isTouch" :open-on-click="false" :open-on-focus="false" top>
						<template v-slot:activator="{ on, attrs }">
							<v-icon class="create-new-project" v-bind="attrs" v-on="on" @click="createNewProject">
								icon-plus
							</v-icon>
						</template>
						<span>{{ "Create a new project." }}</span>
					</v-tooltip>
				</v-slide-x-reverse-transition>
			</template>
		</v-autocomplete>
		<v-autocomplete
			v-else
			:autocomplete="autocomplete"
			dense
			class="autocomplete"
			hide-details
			outlined
			ref="projectSearch"
			label="Project"
			auto-select-first
			no-data-text="No projects found."
			:placeholder="placeholder"
			counter="60"
			:maxLength="60"
			:disabled="hasFreeTrialEnded"
			v-model="localSelectedProject"
			item-text="name"
			item-value="id"
			return-object
			:items="filteredProjectItems"
			@update:search-input="filterAutoCompleteResultsForProject">
			<template v-slot:selection="{ item }">
				<div class="break-word">{{ item.name }}</div>
			</template>
			<template v-slot:item="{ item }">
				<v-list-item-content v-if="!item.edit">
					<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
				</v-list-item-content>
			</template>
			<template v-if="projectsLoaded && filteredProjectItems.length == 0" v-slot:append>
				<v-slide-x-reverse-transition mode="out-in">
					<v-tooltip :open-on-hover="!isTouch" :open-on-click="false" :open-on-focus="false" top>
						<template v-slot:activator="{ on, attrs }">
							<v-icon class="create-new-project" v-bind="attrs" v-on="on" @click="createNewProject">
								icon-plus
							</v-icon>
						</template>
						<span>{{ "Create a new project." }}</span>
					</v-tooltip>
				</v-slide-x-reverse-transition>
			</template>
		</v-autocomplete>
		<CreateProjectDialog ref="createProjectDialog" :default-name="searchStr" />
	</div>
</template>

<script>
	import { hasFreeTrialEnded } from "@/helpers/hasFreeTrialEnded";
	import api from "../api";
	import CreateProjectDialog from "./CreateProjectDialog.vue";
	import { AccessType } from "@/enums/AccessType";

	export default {
		components: {
			CreateProjectDialog,
		},
		props: {
			selectedProject: {
				type: Object,
				required: false,
			},
			density: {
				type: Boolean,
				default: false,
			},
			placeholder: {
				type: String,
				default: "Start typing to find a project",
			}
		},
		data() {
			return {
				projectItems: [],
				filteredProjectItems: [],
				autocomplete: Math.random(),
				me: null,
				searchStr: "",
				projectsLoaded: false,
				hasFreeTrialEnded: false,
				isTouch: false,
			};
		},
		computed: {
			localSelectedProject: {
				get() {
					return this.selectedProject;
				},
				set(newValue) {
					this.$emit("change", newValue);
					this.$emit("update:selectedProject", newValue);
				},
			},
		},
		async mounted() {
			this.isTouch = matchMedia("(hover: none), (pointer: coarse)").matches;
			this.me = await api.getMe(true, true, true);
			await this.loadProjects();
			this.filteredProjectItems = this.projectItems;
			this.projectsLoaded = true;
			if (await hasFreeTrialEnded(this.me.organization)) {
				console.log(this.me.organization);
				this.hasFreeTrialEnded = true;
			}
		},
		methods: {
			createNewProject() {
				this.$refs.createProjectDialog.open(this.searchStr).then((record) => {
					if (record) {
						this.projectItems.push(record);
						this.filterAutoCompleteResultsForProject(this.searchStr);
						this.localSelectedProject = record;
					}
				});
			},
			async loadProjects() {
				let projectItems = await api.getProjects({ pageSize: 1000 });
				const projectShares = await api.getProjectShares({
					pageSize: 1000,
					where: {
						accessType: AccessType.Editor,
						userIdentifier: { $or: [this.me.account.id, this.me.user.email] },
					},
				});
				this.projectItems = projectItems.rows.filter((project) => {
					return projectShares.rows.some((share) => share.projectId === project.id);
				});
			},
			filterAutoCompleteResultsForProject(search) {
				this.searchStr = search;
				if (search && search.length > 0) {
					this.filteredProjectItems = this.projectItems.filter((item) => {
						return item.name.toLowerCase().includes(search.toLowerCase());
					});
				} else {
					this.filteredProjectItems = this.projectItems;
				}
			},
			isAdmin() {
				if (this.me?.account.roles[0].name.toLowerCase() === "user") {
					return false;
				}
				return true;
			},
		},
	};
</script>

<style scoped>
	.break-word {
		overflow-wrap: hidden !important;
		word-break: keep-all;
		max-width: 100% !important;
		overflow: hidden;
	}
	.project-search {
		max-height: fit-content;
	}
	.create-new-project {
		color: #2d74ff;
	}
</style>
